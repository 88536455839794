import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import { CardBody, Row, Col, Card, CardHeader } from "reactstrap";
import { getSubscription } from "../../slices/subscription";
import "suneditor/dist/css/suneditor.min.css";
import { getcontactUs } from "../../slices/contact";
import MessageShowModal from "./MessageShowModal";

const ContactUs = () => {
  document.title = "Contact Us | WriterTools";
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const { contactUsCount, contactUsData, contactUsLoader, error } = useSelector((state) => state.ContactUs);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const nPages = Math.ceil(contactUsCount / limit);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    const params = {};
    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getcontactUs({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getcontactUs({
          ...params,
        })
      );
    }
  }, [searchValue, page, limit]);

  const handleValidDate = (date) => {
    return moment(new Date(date)).format("DD MMM Y");
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return <div className="w-sm">{(page - 1) * limit + row.row.index + 1}</div>;
      },
    },
    {
      Header: "Created Date",
      accessor: "createddate",
      Cell: (cellProps) => (
        <div className="w-md">
          {cellProps?.row?.original?.createdAt ?? "-"}
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellProps) => (
        <div className="w-md">
          {cellProps?.row?.original?.username ?? "-"}
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (cellProps) => (
        <div className="w-md">
          {cellProps?.row?.original?.email ?? "-"}
        </div>
      ),
    },
    {
      Header: "Phone Number	",
      accessor: "phoneNumber",
      Cell: (cellProps) => (
        <div className="w-md">
          {cellProps?.row?.original?.mobileNo ?? "-"}
        </div>
      ),
      
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: (cellProps) => {
        const title = cellProps?.row?.original?.message ?? "-";
        return (
          <div className="w-md">
            {title.length > 60 ? title.substring(0, 60) + "..." : title}
          </div>
        );
      }
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "150px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setContactMessage(cellProps?.row?.original?.message);
                setIsOpenModal(!isOpenModal);
              }}
            >
              <i className="ri-eye-fill align-bottom me-2 text-muted " style={{ fontSize: "23px" }}></i>
            </span>
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Contact</h5>
                  {contactUsCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) => handleOnChangeLimit(Number(e.target.value))}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {contactUsLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {contactUsData && contactUsData.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={contactUsData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Subscriptions..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={contactUsCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          iscreated={true}
                          totalDataCount={contactUsCount}
                          ispaginationshow={contactUsCount > 10 && limit < contactUsCount ? true : false}
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={false}
                            customPageSize={0}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Subscribers..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <MessageShowModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} contactMessage={contactMessage} />
      </div>
    </>
  );
};

export default ContactUs;
