function Googlesignupicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clip-path="url(#clip0_34_4354)">
        <mask
          id="mask0_34_4354"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path d="M25 0H0V25H25V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_34_4354)">
          <path
            d="M0 12.5C0 5.6075 5.6075 0 12.5 0C15.2837 0 17.9184 0.895595 20.1195 2.59L17.2148 6.36335C15.8538 5.3157 14.2234 4.76191 12.5 4.76191C8.2332 4.76191 4.76191 8.2332 4.76191 12.5C4.76191 16.7668 8.2332 20.2381 12.5 20.2381C15.9365 20.2381 18.8569 17.9866 19.8633 14.8809H12.5V10.1191H25V12.5C25 19.3925 19.3925 25 12.5 25C5.6075 25 0 19.3925 0 12.5Z"
            fill="#0AB39C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_34_4354">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Googlesignupicon;
