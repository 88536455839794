import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser, getUsers } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "feather-icons-react/build/IconComponents";
import DeleteModal from "../../Components/Common/DeleteModal";
import Search from "feather-icons-react/build/IconComponents/Search";
import { useNavigate } from "react-router-dom";
import { truncateAddress } from "../../Components/Common/TruncateText";
import { getSubscription } from "../../slices/subscription";

const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: true },
  { label: "Deactive", value: "false" },
];
const index = () => {
  const dispatch = useDispatch();
  document.title = "User | WriterTools";

  const { userCount, userData, userLoader, error } = useSelector(
    (state) => state.User
  );
  const history = useNavigate();

  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [profileModal, setProfileModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const nPages = Math.ceil(userCount / limit);
  const subscriptionData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const subscriptionLoader = useSelector(
    (state) => state.subscription.subscriptionLoader
  );

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = subscriptionData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(subscriptionData.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const makeAPICall = (id) => {
    dispatch(getSubscription({ uid: id }));
  };

  useEffect(() => {
    if (!subscriptionLoader && subscriptionData.length > 0) {
      setLoading(false);
    }
  }, [subscriptionLoader, subscriptionData]);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };

  const [userStatus, setuserStatus] = useState({});
  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value) {
      params.isActive = customerStatus.value;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getUsers({
            ...params,
          })
        )
          .then((res) => {
            if (res?.payload?.status === 401) {
              toast.info("Session expired. Please log in again.");
              localStorage.clear();
              sessionStorage.clear();
              history("/login");
            }
          })
          .catch((err) => {});
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getUsers({
          ...params,
        })
      )
        .then((res) => {
          if (res?.payload?.status === 401) {
            toast.info("Session expired. Please log in again.");
            localStorage.clear();
            sessionStorage.clear();
            history("/login");
          }
        })
        .catch((err) => {});
    }
  }, [customerStatus, searchValue, page, limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const onClickDelete = (status) => {
    const data1 = {
      userid: status?._id,
      isActive: status?.isActive === true ? false : true,
    };

    setuserStatus(data1);
    setDeleteModal(true);
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div className="w-sm">{(page - 1) * 10 + row.row.index + 1}</div>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "createddate",
      Cell: (cellProps) => (
        <div className="w-md">
          {handleValidDate(cellProps?.row?.original?.createdAt)}
        </div>
      ),
    },
    {
      Header: "User Name",
      accessor: "userName",
      Cell: (cellProps) => (
        <div
          className="d-flex align-items-center w-xl"
          style={{ width: "90px" }}
        >
          {cellProps?.row?.original?.profileImage ? (
            <img
              src={cellProps?.row?.original?.profileImage}
              alt=""
              className="avatar-xs rounded-circle me-2"
            />
          ) : (
            <div className="flex-shrink-0 avatar-xs me-2">
              <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                {cellProps?.row?.original?.userName
                  ?.charAt(0)
                  ?.split(" ")
                  ?.slice(-1)
                  ?.toString()
                  ?.charAt(0)}
              </div>
            </div>
          )}
          {cellProps?.row?.original?.userName ?? "-"}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original.userName.toLowerCase();
        const b = rowB.original.userName.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },

    {
      Header: "User Email",
      accessor: (row) => row?.email ?? "-",
    },
    {
      Header: "Product Category",
      accessor: (row) => row?.productCategoryName ?? "-",
      Cell: ({ row }) => (
        <div style={{ minWidth: "150px" }}>
          {row?.original?.productCategoryName ?? "-"}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original?.productCategoryName?.toLowerCase() ?? "";
        const b = rowB.original?.productCategoryName?.toLowerCase() ?? "";
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    {
      Header: "Product Name",
      accessor: (row) => <div className="w-lg">{row?.productName ?? "-"}</div>,
      Cell: ({ row }) => (
        <div className="w-lg">{row?.original?.productName ?? "-"}</div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original?.productName?.toLowerCase() ?? "";
        const b = rowB.original?.productName?.toLowerCase() ?? "";
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    {
      Header: "Product URL",
      Cell: (cellProps) => (
        <div style={{ minWidth: "150px" }}>
          {truncateAddress(cellProps?.row?.original?.productURL ?? "-", 40)}
        </div>
      ),
    },
    {
      Header: "Interested Categories",
      Cell: (cellProps) => (
        <div style={{ width: "250px" }}>
          {cellProps?.row?.original?.interestedCategoriesNames.length > 0
            ? cellProps?.row?.original?.interestedCategoriesNames?.map(
                (item, index) => <span key={index}>{item}, </span>
              )
            : "-"}
        </div>
      ),
    },

    {
      Header: "STATUS",
      Cell: (cellProps) => {
        switch (cellProps?.row?.original.isActive) {
          case true:
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case false:
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Deactive
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                Unknown
              </span>
            );
        }
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "150px" }}>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-eye-fill align-bottom me-2 text-muted "
                style={{ fontSize: "23px" }}
                onClick={() => {
                  setProfileData(cellProps?.row?.original);
                  setProfileModal(true);
                  makeAPICall(cellProps?.row?.original?._id);
                }}
              ></i>
            </span>

            <Button
              color={
                cellProps?.row?.original.isActive === true
                  ? "danger"
                  : "success"
              }
              className="btn-md "
              onClick={() => {
                onClickDelete(cellProps?.row?.original);
              }}
            >
              {cellProps?.row?.original.isActive === true
                ? "Deactivate"
                : "Active"}
            </Button>
          </div>
        );
      },
    },
  ]);
  const handleDeleteUser = () => {
    setLoader(true);
    dispatch(
      DeleteUser({ id: userStatus.userid, isActive: userStatus.isActive })
    )
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          if (userStatus?.isActive === false) {
            toast.success("Deactive successfully");
          } else {
            toast.success("Active successfully");
          }
          setDeleteModal(false);
          dispatch(
            getUsers({
              search: searchValue?.trimEnd() || null,
              page: 1,
              isActive: customerStatus.value || null,
            })
          );
          setLoader(false);
        } else {
          toast.error(res?.payload?.data?.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        setLoader(false);
      });
  };

  return (
    <>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          text={userStatus?.isActive === false ? "Deactive" : "Active"}
          onDeleteClick={() => handleDeleteUser()}
          onCloseClick={() => setDeleteModal(false)}
          loader={loader}
        />
        <Modal
          isOpen={profileModal}
          centered
          id="exampleModal"
          className="user-profile-modal-main"
        >
          <ModalHeader
            toggle={() => {
              setProfileModal(false);
            }}
          >
            User Profile
          </ModalHeader>
          <ModalBody>
            <div className="profile-container">
              <div className="profile-section">
                <div className="profile-column">
                  <div className="profile-row">
                    <strong>User Name : </strong>
                    <span>{profileData.userName ?? "-"}</span>
                  </div>
                  <div className="profile-row">
                    <strong>Product Name : </strong>
                    <span>{profileData.productName || "-"}</span>
                  </div>
                  <div className="profile-row">
                    <strong>Product Category : </strong>
                    <span>{profileData.productCategoryName || "-"}</span>
                  </div>
                </div>
                <div className="profile-column">
                  <div className="profile-row">
                    <strong>User Email : </strong>
                    <span>{profileData.email || "-"}</span>
                  </div>
                  <div className="profile-row">
                    <strong>Product URL : </strong>
                    <span className="text-break">
                      {truncateAddress(profileData.productURL || "-")}
                    </span>
                  </div>
                  <div className="profile-row">
                    <strong>Interested Categories : </strong>
                    <span>
                      {truncateAddress(profileData.interestedCategoriesNames?.join(", ") || "-")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 profile-modal-payment-history-table-main">
              <h5 className="profile-modal-payment-history-table-tite">
                Payment History
              </h5>
              {loading ? (
                <p className="noRecord">Loading...</p>
              ) : (
                <>
                  {subscriptionData.length === 0 ? (
                    <div className="noRecord">No records found</div>
                  ) : (
                    <>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Created Date</th>
                            <th>Blog Title</th>
                            <th>Amount</th>
                            <th>Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentRecords.map((record, index) => (
                            <tr key={record._id}>
                              <td>
                                {(currentPage - 1) * recordsPerPage + index + 1}
                              </td>
                              <td>
                                {moment(record.createdAt).format("DD MMM Y")}
                              </td>
                              <td>
                                {record?.blogId?.title?.length > 70
                                  ? record?.blogId?.title?.substring(0, 70) + "..."
                                  : record?.blogId?.title || "-"}
                              </td>

                              <td>{record.amount}</td>

                              <td>
                                {record?.status === "Paid" ? (
                                  <div className="successed">Successful</div>
                                ) : (
                                  <div className="failed">Failed</div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {subscriptionData.length > recordsPerPage && (
                        <Pagination>
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                              previous
                              onClick={() => handlePageChange(currentPage - 1)}
                            />
                          </PaginationItem>
                          {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem
                              active={index + 1 === currentPage}
                              key={index}
                            >
                              <PaginationLink
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink
                              next
                              onClick={() => handlePageChange(currentPage + 1)}
                            />
                          </PaginationItem>
                        </Pagination>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setProfileModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Users</h5>
                  {userCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {userLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {userData && userData.length ? (
                        <TableContainer
                          columns={columns || []}
                          data={userData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          isCustomerFilter={true}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search User..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={userCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          totalDataCount={userCount}
                          ispaginationshow={
                            userCount > 10 && limit < userCount ? true : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search User..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={userCount > 10 ? true : false}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default index;
