function Totalusersicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clip-path="url(#clip0_34_4316)">
        <mask
          id="mask0_34_4316"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path d="M25 0H0V25H25V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_34_4316)">
          <path
            d="M15.0864 5.59148C16.2423 6.31623 17.0529 7.54268 17.2005 8.96633C17.6852 9.19433 18.2142 9.31278 18.7498 9.31323C20.775 9.31323 22.4164 7.67183 22.4164 5.64703C22.4164 3.62186 20.775 1.98047 18.7498 1.98047C16.744 1.98109 15.117 3.59316 15.0864 5.59148ZM12.6844 13.0986C14.7095 13.0986 16.351 11.4569 16.351 9.43213C16.351 7.40728 14.7092 5.76588 12.6844 5.76588C10.6595 5.76588 9.0172 7.40758 9.0172 9.43243C9.0172 11.4573 10.6595 13.0986 12.6844 13.0986ZM14.2396 13.3486H11.1285C8.53985 13.3486 6.4339 15.4548 6.4339 18.0434V21.8482L6.4436 21.9078L6.70565 21.9898C9.17605 22.7617 11.3222 23.0191 13.0887 23.0191C16.539 23.0191 18.539 22.0354 18.6622 21.9727L18.9071 21.8488H18.9333V18.0434C18.9343 15.4548 16.8283 13.3486 14.2396 13.3486ZM20.3057 9.56348H17.2186C17.1872 10.7555 16.686 11.8868 15.824 12.7108C18.1249 13.395 19.8085 15.5288 19.8085 18.049V19.2215C22.8566 19.1098 24.6132 18.2459 24.7289 18.1879L24.9738 18.0637H25V14.2577C25 11.6694 22.894 9.56348 20.3057 9.56348ZM6.2508 9.31388C6.96805 9.31388 7.6354 9.10453 8.20075 8.74793C8.3781 7.60053 8.98855 6.56483 9.9064 5.85388C9.91015 5.78523 9.9167 5.71723 9.9167 5.64793C9.9167 3.6228 8.275 1.9814 6.2508 1.9814C4.22532 1.9814 2.58424 3.6228 2.58424 5.64793C2.58424 7.67218 4.22532 9.31388 6.2508 9.31388ZM9.54355 12.7108C8.6864 11.8908 8.18585 10.7667 8.1499 9.58093C8.0354 9.57253 7.92215 9.56348 7.80545 9.56348H4.69456C2.10596 9.56348 0 11.6694 0 14.2577V18.0631L0.0096718 18.1217L0.271746 18.2044C2.25352 18.8231 4.02222 19.1083 5.55845 19.1981V18.049C5.5591 15.5288 7.242 13.3957 9.54355 12.7108Z"
            fill="#0AB39C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_34_4316">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Totalusersicon;
