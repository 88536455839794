import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

function MessageShowModal({ isOpenModal, setIsOpenModal, contactMessage }) {
  return (
    <Modal isOpen={isOpenModal} centered id="exampleModal" className="contact-modal-main">
      <ModalHeader
        toggle={() => {
          setIsOpenModal(false);
        }}
      >
        Message
      </ModalHeader>
      <ModalBody><h5>{contactMessage}</h5></ModalBody>
      <div className="modal-footer">
        <Button
          color="light"
          onClick={() => {
            setIsOpenModal(false);
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default MessageShowModal;
