import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, thunkHandler } from "../../helpers/base";

const initialState = {
  contactUsData: [],
  contactUsCount: 0,
  contactUsLoader: false,
};

export const getcontactUs = createAsyncThunk("user-services/contactus/getContacts", async (data, thunkAPI) => {
  const response = await thunkHandler(get("user-services/contactus/getContacts", data), thunkAPI);
  if (response.success) {
    return response.payload;
  } else {
    return thunkAPI.rejectWithValue(response.message);
  }
});

const contactSlice = createSlice({
  name: "ContactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getcontactUs.pending, (state, action) => {
        state.contactUsLoader = true;
      })
      .addCase(getcontactUs.fulfilled, (state, action) => {
        state.contactUsData = action.payload;
        state.contactUsCount = action.payload.counts;
        state.contactUsLoader = false;
      })
      .addCase(getcontactUs.rejected, (state, action) => {
        state.contactUsData = [];
        state.contactUsLoader = false;
      });
  },
});
export default contactSlice.reducer;
