import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import { CardBody, Row, Col, Card, CardHeader } from "reactstrap";
import { getSubscription } from "../../slices/subscription";
import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";

const Subscription = () => {
  document.title = "Subscriptions | WriterTools";

  const dispatch = useDispatch();
  const { subscriptionCount, subscriptionData, subscriptionLoader, error } =
    useSelector((state) => state.subscription);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const nPages = Math.ceil(subscriptionCount / limit);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    const params = {};
    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }

    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getSubscription({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getSubscription({
          ...params,
        })
      );
    }
  }, [searchValue, page, limit]);

  const handleValidDate = (date) => {
    return moment(new Date(date)).format("DD MMM Y");
  };

  const handleCopyLink = (slugId) => {
    const link = `https://writertools.ai/${slugId}`;
    navigator.clipboard.writeText(link);
    toast.success("Link copied!");
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <div className="w-sm">{(page - 1) * limit + row.row.index + 1}</div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: (row) => handleValidDate(row?.createdAt),
      filterable: false,
    },
    {
      Header: "User Name",
      accessor: (row) => row?.uid?.userName ?? "-",
      filterable: false,
    },
    {
      Header: "Email",
      accessor: (row) => row?.uid?.email ?? "-",
      filterable: false,
    },
    {
      Header: "Title",
      accessor: (row) => {
        const title = row?.blogId?.title ?? "-";
        return title.length > 120 ? title.substring(0, 120) + "..." : title;
      },
      filterable: false,
    },
    {
      Header: "Slug ID",
      accessor: (row) => row?.blogId?.slugId ?? "-",
      filterable: false,
    },
    {
      Header: "Amount",
      accessor: (row) => row?.amount ?? "-",
      filterable: false,
    },
    {
      Header: "Link",
      Cell: (cellProps) => {
        const slugId = cellProps?.row?.original?.blogId?.slugId;
        return (
          <div className="d-flex " style={{ width: "100px" }}>
            {slugId ? (
              <span
                style={{
                  cursor: "pointer",
                  color: "green",
                  textDecoration: "none",
                }}
                onClick={() => handleCopyLink(slugId)}
              >
                Copy URL
              </span>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Subscriptions</h5>
                  {subscriptionCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {subscriptionLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {subscriptionData && subscriptionData.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={subscriptionData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Subscriptions..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={subscriptionCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          iscreated={true}
                          totalDataCount={subscriptionCount}
                          ispaginationshow={
                            subscriptionCount > 10 && limit < subscriptionCount
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={false}
                            customPageSize={0}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Subscribers..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                            // addbuttontext={"Add Category"}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Subscription;
