
function Totalcustomusersicon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
<g clip-path="url(#clip0_34_4322)">
<mask id="mask0_34_4322"  style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
<path d="M25 0H0V25H25V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_34_4322)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4999 6.88475C9.8156 6.88475 7.5656 8.77815 7.01425 11.2994L3.00976 4.36347C3.21796 4.12128 3.43519 3.887 3.66098 3.66113C6.02195 1.30025 9.161 0 12.4999 0C15.8387 0 18.9777 1.30025 21.3387 3.66113C22.2977 4.62021 23.0818 5.70765 23.6747 6.88475H12.4999ZM16.6504 12.5C16.6504 14.7922 14.7922 16.6504 12.5 16.6504C10.2078 16.6504 8.3496 14.7922 8.3496 12.5C8.3496 10.2078 10.2078 8.3496 12.5 8.3496C14.7922 8.3496 16.6504 10.2078 16.6504 12.5ZM14.2031 17.851C13.6525 18.0265 13.078 18.1156 12.5 18.1152C10.4141 18.1152 8.59045 16.9719 7.62215 15.2792C7.61515 15.2685 7.6084 15.2577 7.6019 15.2467L2.05132 5.6328C0.716845 7.6543 0 10.022 0 12.5C0 15.8389 1.30025 18.9779 3.66113 21.3389C5.46985 23.1475 7.7355 24.3328 10.1975 24.7887L14.2031 17.851ZM18.1153 12.5C18.1153 10.8574 17.4063 9.37735 16.2783 8.3496H24.2971C24.759 9.66555 25 11.0641 25 12.5C25 15.8389 23.6998 18.9779 21.3389 21.3389C18.9779 23.6997 15.8389 25 12.5 25C12.2586 25 12.0184 24.9924 11.7793 24.9789L17.3311 15.3629C17.3345 15.357 17.3374 15.3509 17.3403 15.3449C17.3421 15.3413 17.3439 15.3377 17.3458 15.3342C17.8345 14.5017 18.1153 13.5331 18.1153 12.5Z" fill="#0AB39C"/>
</g>
</g>
<defs>
<clipPath id="clip0_34_4322">
<rect width="25" height="25" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default Totalcustomusersicon