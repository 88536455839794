import React from 'react'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'


const BlogCount = ({dashboardData,handleOnChange}) => {
  return (
    <>
     <h4 className="fs-20 mb-4">Blogs</h4>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Total Blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfBlogs}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/admin-blogs">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Admin Blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfBlogsCreatedByAdmin}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/blogs">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      User Blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfBlogsCreatedByUser}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/blogs?status=Approved">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      approved blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfApprovedBlogs}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/blogs?status=Rejected">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Rejected Blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfRejectedBlogs}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/blogs?status=Pending">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Pending Blogs
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfPendingBlogs}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default BlogCount