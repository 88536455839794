import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, thunkHandler } from "../../helpers/base";

const initialState = {
  subscriptionData: [],
  subscriptionCount: 0,
  subscriptionLoader: false,
};

export const getSubscription = createAsyncThunk(
  "blog-services/payment/get-payment-history",
  async (data, thunkAPI) => {
    const response = await thunkHandler(
      get("blog-services/payment/get-payment-history", data),
      thunkAPI
    );
    if (response.success) {
      return response.payload;
    } else {
      return thunkAPI.rejectWithValue(response.message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "Subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state, action) => {
        state.subscriptionLoader = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.subscriptionData = action.payload.payments;
        state.subscriptionCount = action.payload.counts;
        state.subscriptionLoader = false;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.subscriptionData = [];
        state.subscriptionLoader = false;
      });
  },
});

export default subscriptionSlice.reducer;