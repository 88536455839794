import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import UsersCount from "./UsersCount";
import BlogCount from "./BlogCount";
import { toast } from "react-toastify";
import RevenueCount from "./RevenueCount";
import { useNavigate } from "react-router-dom";
import "rsuite/DateRangePicker/styles/index.css";
import { getDashboard } from "../../slices/dashboard";
import useDebounce from "../../Components/Hooks/useDebounce";

const Widgets = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { dashboardData } = useSelector((state) => state.Dashboard);
  const [userDate, setUserDate] = useState({
    userStartDate: null,
    userEndDate: null,
  });

  const [revenueDate, setRevenueDate] = useState({
    revenueStartDate: null,
    revenueEndDate: null,
  });

  const debouncedUserDate = useDebounce(userDate?.userStartDate && userDate?.userEndDate, 500);
  const debouncedRevenueDate = useDebounce(revenueDate?.revenueStartDate && revenueDate?.revenueEndDate, 500);


  useEffect(() => {
    let params = "";
    if (userDate?.userStartDate && userDate?.userEndDate) {
      params += `&userStartDate=${moment(userDate?.userStartDate).format(
        "YYYY-MM-DD"
      )}`;
      params += `&userEndDate=${moment(userDate?.userEndDate).format(
        "YYYY-MM-DD"
      )}`;
    }
    if (revenueDate?.revenueStartDate && revenueDate?.revenueEndDate) {
      params += `&revenueStartDate=${moment(revenueDate?.revenueStartDate).format(
        "YYYY-MM-DD"
      )}`;
      params += `&revenueEndDate=${moment(revenueDate?.revenueEndDate).format(
        "YYYY-MM-DD"
      )}`;
    }
    getDashboardData(params);
  }, [debouncedUserDate, debouncedRevenueDate]);

  const getDashboardData = (params) => {
    dispatch(getDashboard(params))
      .then((res) => {
        if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          localStorage.clear();
          sessionStorage.clear();
          history("/login");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOnChange = (date, type) => {
    let [Start_Date, End_Date] = date?.length > 0 ? date : [null, null];
    if (type === "user") {
      setUserDate({ userStartDate: Start_Date, userEndDate: End_Date });
    } else if (type === "revenue") {
      setRevenueDate({
        revenueStartDate: Start_Date,
        revenueEndDate: End_Date,
      });
    }
  };

  return (
    <React.Fragment>      
        <UsersCount handleOnChange={handleOnChange} dashboardData={dashboardData}/>
        <BlogCount handleOnChange={handleOnChange} dashboardData={dashboardData}/>
        <RevenueCount handleOnChange={handleOnChange} dashboardData={dashboardData}/>
    </React.Fragment>
  );
};

export default Widgets;
