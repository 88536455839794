import React from "react";
import { Navigate } from "react-router-dom";

import DashboardEcommerce from "../pages/DashboardEcommerce";

import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//Invoices
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceCreate from "../pages/Invoices/InvoiceCreate";
import InvoiceDetails from "../pages/Invoices/InvoiceDetails";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct";
import Users from "../pages/Users/index";
import Tools from "../pages/Blogs/index";
import Settings from "../pages/Profile/Settings/Settings";
import ToolSubCategory from "../pages/Blogs/ToolSubCategory";
import AdminBlogs from "../pages/Blogs/AdminBlogs";
import Blogs from "../pages/Blogs/Blogs";
import Gpts from "../pages/Gpts/index";
import GptCategory from "../pages/Gpts/GptCategory";
import CreateGpt from "../pages/Gpts/CreateGpt";
import GptSubCategory from "../pages/Gpts/GptSubCategory";
import BlogsCategory from "../pages/Blogs/BlogsCategory";
import CreateBlog from "../pages/Blogs/CreateBlog";
import PrivacyPolicy from "../pages/Blogs/PrivacyPolicy";
import NewsLetter from "../pages/Blogs/NewsLetter";
import Subscription from "../pages/Blogs/Subscription";
import ContactUs from "../pages/ContactUs";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/user", component: <Users /> },
  { path: "/profile", component: <Settings /> },
  { path: "/create-blog", component: <CreateBlog /> },
  { path: "/update-admin-blogs/:slugId", component: <CreateBlog /> },
  { path: "/update-user-tool/:slugId", component: <CreateBlog /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "/admin-blogs", component: <AdminBlogs /> },

  { path: "/tools", component: <Tools /> },
  { path: "/blogs-category", component: <BlogsCategory /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/subscription", component: <Subscription /> },
  { path: "/contact", component: <ContactUs /> },

  { path: "/news-letter", component: <NewsLetter /> },
  { path: "/tools-subcategory", component: <ToolSubCategory /> },
  //gpt
  { path: "/gpts", component: <Gpts /> },
  { path: "/create-gpt", component: <CreateGpt /> },
  { path: "/update-gpt/:slugId", component: <CreateGpt /> },

  { path: "/gpts-category", component: <GptCategory /> },
  { path: "/gpts-subcategory", component: <GptSubCategory /> },

  //Invoices
  { path: "/apps-invoices-list", component: <InvoiceList /> },
  { path: "/apps-invoices-details", component: <InvoiceDetails /> },
  { path: "/apps-invoices-create", component: <InvoiceCreate /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
  {
    path: "/apps-ecommerce-product-details",
    component: <EcommerceProductDetail />,
  },
  { path: "/apps-ecommerce-add-product", component: <EcommerceAddProduct /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  //AuthenticationInner pages
];

export { authProtectedRoutes, publicRoutes };
