import React from 'react'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Todaytotalusericon from '../../assets/icons/todaytotalusericon'
import Thismonthtotalusersicon from '../../assets/icons/thismonthtotalusersicon'
import Totalusersicon from '../../assets/icons/totalusersicon'
import { DateRangePicker } from 'rsuite'
import { predefinedRanges } from "../../helpers/constnt";
import Googlesignupicon from '../../assets/icons/googlesignupicon'
import Totalcustomusersicon from '../../assets/icons/totalcustomusersicon'
import Totalcustomsignup from '../../assets/icons/totalcustomsignup'

const UsersCount = ({dashboardData,handleOnChange}) => {
  return (
    <>
  <Row className="align-items-center mb-4">
        <Col xs={12} md={6}>
          <h4 className="fs-20 mb-0">Users</h4>
        </Col>
        <Col xs={12} md={6} className="text-md-end">
          <DateRangePicker
            ranges={predefinedRanges}
            placeholder="Select Date"
            placement="bottomEnd"
            onChange={(date) => handleOnChange(date, "user")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Today`s Total Users
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          end={dashboardData?.totalTodayUsers}
                          duration={4}
                        />
                      </span>
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Todaytotalusericon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      This Month Total Users
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalThisMonthUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Thismonthtotalusersicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Total Users
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalNumberOfUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Totalusersicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Today`s Google Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalTodaySocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Googlesignupicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      This Month Google Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalThisMonthSocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Googlesignupicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Total Google Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfSocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Googlesignupicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Today`s Custom Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalTodayNonSocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Totalcustomusersicon />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      This Month Custom Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalThisMonthNonSocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <i className="ri-account-circle-line text-success"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody>
              <Link to="/user">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Total Custom Signup
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary ">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfNonSocialUsers}
                        duration={4}
                      />
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-soft-success">
                      <Totalcustomsignup />
                    </span>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default UsersCount