import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
//Invoice
import UsersReducers from "./user/index";
import ToolReducer from "./blogs/index";
import GptReduces from "./gpts/index";
import PrivacyPolicyReducer from './privacypolicy/index'
import SubscriptionReducer from "./subscription/index"

import DashboardReducer from "./dashboard/index";
import NewsLetterReducer from "./newsletter/newsletter";
import UpdateProfileReducer from "./profile/index"
import ContactUsReducer from "./contact/index";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  User: UsersReducers,
  Tool: ToolReducer,
  Gpt: GptReduces,
  NewLetter: NewsLetterReducer,
  PrivacyPolicy: PrivacyPolicyReducer,
  UpdateProfile : UpdateProfileReducer,
  subscription: SubscriptionReducer,
  ContactUs: ContactUsReducer,
 });

export default rootReducer;
