import React from 'react'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { DateRangePicker } from 'rsuite'
import { predefinedRanges } from "../../helpers/constnt";

const RevenueCount = ({dashboardData,handleOnChange}) => {
  return (
    <>
       <Row className="align-items-center mb-4">
        <Col xs={12} md={6}>
          <h4 className="fs-20 mb-0">Revenue</h4>
        </Col>
        <Col xs={12} md={6} className="text-md-end">
          <DateRangePicker
            ranges={predefinedRanges}
            placeholder="Select Date"
            placement="bottomEnd"
            onChange={(date) => handleOnChange(date, "revenue")}
            className="date-range-picker"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
            <CardBody className="w-[317.23px] h-[123.5px]"
            >
              <Link to="/subscription">
                <div className="d-flex align-items-center justify-content-between">
                  {/* Revenue Text */}
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      Today's Revenue
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-2 bg-soft-info">
                    <span
                          className="counter-value ff-secondary fs-18 "
                          style={{ color: "dodgerblue", fontWeight: "bold" }}
                          data-target="559.25"
                        >
                          <CountUp
                            start={0}
                            end={dashboardData?.todayRevenue}
                            duration={4}
                          />
                        </span>
                    </span>
                  </div>
                </div>
                {/* Revenue Amount */}
                <div className="mt-4">
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target="559.25">
                      $
                      <CountUp
                        start={0}
                        end={dashboardData?.todayRevenue}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
          
            <CardBody
             className="w-[317.23px] h-[123.5px]"
            >
              <Link to="/subscription">
                <div className="d-flex align-items-center justify-content-between">
                  {/* Revenue Text */}
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    This Month Revenue
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-2 bg-soft-info">
                    <span
                          className="counter-value ff-secondary fs-18 "
                          style={{ color: "dodgerblue", fontWeight: "bold" }}
                          data-target="559.25"
                        >
                          <CountUp
                            start={0}
                            end={dashboardData?.thisMonthRevenue}
                            duration={4}
                          />
                        </span>
                    </span>
                  </div>
                </div>
                {/* Revenue Amount */}
                <div className="mt-4">
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target="559.25">
                      $
                      <CountUp
                        start={0}
                        end={dashboardData?.thisMonthRevenue}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Card className="card-animate">
             <CardBody
            className="w-[317.23px] h-[123.5px]"
            >
              <Link to="/subscription">
                <div className="d-flex align-items-center justify-content-between">
                  {/* Revenue Text */}
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Revenue
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-2 bg-soft-info">
                    <span
                          className="counter-value ff-secondary fs-18 "
                          style={{ color: "dodgerblue", fontWeight: "bold" }}
                          data-target="559.25"
                        >
                          <CountUp
                            start={0}
                            end={dashboardData?.totalRevenue}
                            duration={4}
                          />
                        </span>
                    </span>
                  </div>
                </div>
                {/* Revenue Amount */}
                <div className="mt-4">
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target="559.25">
                      $
                      <CountUp
                        start={0}
                        end={dashboardData?.totalRevenue}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: "200px" }}></Row>
    </>
  )
}

export default RevenueCount