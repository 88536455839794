import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import moment from "moment";
import {
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { DeleteUser } from "../../slices/user";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { deleteBlogs, getBlogs } from "../../slices/blogs";
import { useLocation } from "react-router-dom";
import ImagePreviewModal from "../../Components/Common/ImagePreviewModal";
import { ChangeBlogsStatus, ChangeEditorPickStatus } from "../../slices/gpts";
import { truncateAddress } from "../../Components/Common/TruncateText";
import { marked } from "marked";
import BlogPreviewModal from "../../Components/Common/BlogPreviewModal/BlogPreviewModal";

const customerstatus = [
  { label: "All", value: "all" },
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
];

const editorpickTrendingBlogcustomerStatus = [
  { label: "All", value: "" },
  { label: "EditorPick", value: "isEditorPick" },
  { label: "Trending", value: "isTrending" },
];

const index = () => {
  document.title = "Blogs | WriterTools";
  const BlogRedirectLink = process.env.REACT_APP_BLOG_URL;

  const dispatch = useDispatch();
  const { toolCount, toolData, toolLoader } = useSelector(
    (state) => state.Tool
  );
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const status = params.get("status");

  const [customerStatus, setcustomerStatus] = useState(
    status
      ? customerstatus.find((item) => item.value === status)
      : customerstatus[0]
  );
  const [
    editorpickTrendingBlogcustomerstatus,
    setEditorpickTrendingBlogcustomerstatus,
  ] = useState(editorpickTrendingBlogcustomerStatus[0]);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [blogDetailModal, setBlogDetailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [imageLink, setImgLink] = useState("");
  const [limit, setLimit] = useState(10);
  const [isTrendingLoader, setIsTrendingLoader] = useState(false);
  const [isEditorPickLoader, setisEditorPickLoader] = useState(false);

  const [blogDelete, setBlogDelete] = useState(false);
  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const apiKey = {
    Twitter: "twitterLink",
    YouTube: "youtubeLink",
    Instagram: "instagramLink",
    Facebook: "facebookLink",
    LinkedIn: "linkedinLink",
  };
  const nPages = Math.ceil(toolCount / limit);
  const [userStatus, setuserStatus] = useState({});
  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus, editorpickTrendingBlogcustomerstatus]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus, editorpickTrendingBlogcustomerstatus]);

  useEffect(() => {
    const params = {};
    
    if (customerStatus?.value) {
      params.status =
        customerStatus.value === "all" ? null : customerStatus.value;
    }
    if (editorpickTrendingBlogcustomerstatus?.value) {
      params[editorpickTrendingBlogcustomerstatus.value] = true;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }

    params.createdBy = "User";

    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getBlogs({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getBlogs({
          ...params,
        })
      );
    }
  }, [
    customerStatus,
    pathname,
    editorpickTrendingBlogcustomerstatus,
    searchValue,
    page,
    limit,
  ]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const onClickDelete = (id, status) => {
    let data;
    if (status === "both") {
      data = {
        appIds: id?._id,
        status: id?.status === "Rejected" ? "Approved" : "Rejected",
      };
    } else {
      data = {
        appIds: id,
        status: status,
      };
    }

    setuserStatus(data);
    setDeleteModal(true);
  };
  const onBlogDelete = (id) => {
    setuserStatus(id);
    setBlogDelete(true);
  };
  const handleDeleteBlog = () => {
    setLoader(true);
    dispatch(
      deleteBlogs({
        id: userStatus,
      })
    )
      .then((res) => {
        if (res?.payload?.success === true) {
          toast.success("Blog deleted successfully");
          setBlogDelete(false);
          setLoader(false);
          dispatch(
            getBlogs({
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              status: customerStatus.value ? customerStatus.value : null,
              page: page,
              createdBy: "User",
            })
          );
        } else if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.message);
      });
  };

  const handleIsTrendingChange = (e, TrendingDataId) => {
    const data = {
      isTrending: e.target.checked,
      appIds: TrendingDataId,
    };
    setIsTrendingLoader(true);
    dispatch(ChangeBlogsStatus(data))
      .then((res) => {
        if (res?.payload?.success === true) {
          setIsTrendingLoader(false);
          toast.success("Updated successfully");
          dispatch(
            getBlogs({
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              status: customerStatus.value ? customerStatus.value : null,
              page: page,
              createdBy: "User",
            })
          );
        } else if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          setIsTrendingLoader(false);
        }else{
          toast.error(res?.payload?.data?.message)
          setIsTrendingLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsTrendingLoader(false);
      });
  };

  const handleIsEditorPickChange = (e, isEditorPickId) => {
    const data = {
      isEditorPick: e.target.checked,
      appIds: isEditorPickId,
    };
    setisEditorPickLoader(true);
    dispatch(ChangeEditorPickStatus(data))
      .then((res) => {
        if (res?.payload?.success === true) {
          setisEditorPickLoader(false);
          toast.success("Updated successfully");
          dispatch(
            getBlogs({
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              status: customerStatus.value ? customerStatus.value : null,
              page: page,
              createdBy: "User",
            })
          );
        } else if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          setisEditorPickLoader(false);
        }else{
          toast.error(res?.payload?.data?.message)
          setisEditorPickLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setisEditorPickLoader(false);
      });
  };

  // const handleOnOpenImage = (row) => {
  //   const data = {
  //     img: row?.coverPhoto,
  //     name: row?.title,
  //   };

  //   setImgLink(data);
  //   setImageModal(true);
  // };
  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div style={{ width: "60px" }}>
              {(page - 1) * 10 + row.row.index + 1}
            </div>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "createdDate",
      Cell: (cellProps) => (
        <div style={{ width: "160px" }}>
          {handleValidDate(cellProps?.row?.original?.createdAt) ?? "-"}
        </div>
      ),
    },
    {
      Header: "Creator Name",
      accessor: "userName",
      Cell: (cellProps) => (
        <div style={{ width: "170px" }}>
          {cellProps?.row?.original?.Users?.userName ?? "-"}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original?.Users?.userName.toLowerCase();
        const b = rowB.original?.Users?.userName.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    {
      Header: "Creator Email ID",
      accessor: "email",
      Cell: (cellProps) => (
        <div style={{ width: "170px" }}>
          {cellProps?.row?.original?.Users?.email ?? "-"}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original?.Users?.email.toLowerCase();
        const b = rowB.original?.Users?.email.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    // {
    //   Header: "Blog Category",
    //   Cell: (cellProps) => (
    //     <div style={{ width: "300px" }}>
    //       {cellProps?.row?.original?.BlogCategory?.length > 0
    //         ? cellProps?.row?.original?.BlogCategory?.map((item, index) => (
    //             <span key={index}>{item?.title}, </span>
    //           ))
    //         : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Cover Photo",
    //   Cell: (cellProps) => (
    //     <>
    //       <div
    //         className="d-flex align-items-center w-lg"
    //         onClick={() => handleOnOpenImage(cellProps?.row?.original)}
    //       >
    //         <img
    //           style={{ objectFit: "contain" }}
    //           src={cellProps?.row?.original?.coverPhoto}
    //           alt=""
    //           className="avatar-xs me-2"
    //         />
    //       </div>
    //     </>
    //   ),
    // },
    {
      Header: "Blog Title",
      accessor: (row) => row?.title ?? "-",
      Cell: ({ row }) => (
        <div style={{ width: "290px" }}>
          {row?.original?.status !== "rejected" ? (
            <a
              href={`${BlogRedirectLink}${row?.original?.slugId}`}
              target="_blank"
            >
              <p style={{ "text-transform": "capitalize" }}>
                {/* {row?.original?.title ?? "-"} */}
                {row?.original?.title?.length > 120
                  ? row?.original?.title?.substring(0, 120) + "..."
                  : row?.original?.title ?? "-"}
              </p>
            </a>
          ) : (
            <>
              <p style={{ "text-transform": "capitalize" }}>
                {/* {row?.original?.title ?? "-"} */}
                {row?.original?.title?.length > 120
                  ? row?.original?.title?.substring(0, 120) + "..."
                  : row?.original?.title ?? "-"}
              </p>
            </>
          )}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original.title.toLowerCase();
        const b = rowB.original.title.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    // {
    //   Header: "Short Description",
    //   accessor: "sortDescription",
    //   Cell: (cellProps) => (
    //     <div style={{ width: "260px" }} className="text-truncate-three-lines">
    //       {cellProps?.row?.original?.sortDescription ?? "-"}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Keywords",
    //   Cell: (cellProps) => (
    //     <div className="w-lg" style={{ width: "200px" }}>
    //       {cellProps?.row?.original?.keyWords?.length > 0
    //         ? cellProps?.row?.original?.keyWords?.map((item, index) => (
    //             <span key={index}>{item}, </span>
    //           ))
    //         : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Website URL",
    //   Cell: (cellProps) => (
    //     <>{truncateAddress(cellProps?.row?.original?.websiteLink ?? "-", 40)}</>
    //   ),
    // },
    {
      Header: "Slug URL",
      Cell: (cellProps) => (
        <div className="text-truncate-two-lines" style={{ width: "200px" }}>
          {cellProps?.row?.original?.slugId ?? "-"}
        </div>
      ),
    },
    {
      Header: "Likes Count",
      Cell: (cellProps) => (
        <div className="w-lg">{cellProps?.row?.original?.likes || 0}</div>
      ),
    },
    {
      Header: "Dislikes Count",
      Cell: (cellProps) => (
        <div className="w-lg">{cellProps?.row?.original?.dislikes || 0}</div>
      ),
    },
    {
      Header: "Status",
      accessor: (row) =>
        (
          <div
            className={
              row?.status === "Pending"
                ? "text-warning"
                : row?.status === "Approved"
                ? "text-success"
                : row?.status === "Rejected"
                ? "text-danger"
                : null
            }
          >
            <b style={{ "text-transform": "capitalize" }}>{row?.status}</b>
          </div>
        ) ?? "-",
      filterable: false,
    },
    {
      Header: "Updated Date",
      Cell: (cellProps) => (
        <div className="w-lg">
          {handleValidDate(cellProps?.row?.original?.updatedAt)}
        </div>
      ),
    },
    {
      Header: "Trending",
      Cell: (cellProps) => (
        <div className="w-lg">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck4"
              disabled={cellProps?.row?.original?.status != "Approved"}
              checked={cellProps?.row?.original?.isTrending}
              onChange={(e) =>
                handleIsTrendingChange(e, cellProps?.row?.original?._id)
              }
            />
          </div>
        </div>
      ),
    },
    {
      Header: "Editor Pick",
      Cell: (cellProps) => (
        <div className="w-lg">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck4"
              disabled={cellProps?.row?.original?.status != "Approved"}
              checked={cellProps?.row?.original?.isEditorPick}
              onChange={(e) =>
                handleIsEditorPickChange(e, cellProps?.row?.original?._id)
              }
            />
          </div>
        </div>
      ),
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "180px" }}>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-eye-fill align-bottom me-2 text-muted "
                style={{ fontSize: "23px" }}
                onClick={() => {
                  setProfileData(cellProps?.row?.original);
                  setBlogDetailModal(true);
                }}
              ></i>
            </span>
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-primary btn-sm dropdown "
                style={{ marginRight: "20px" }}
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                {cellProps?.row?.original?.status === "Pending" ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickDelete(
                          cellProps?.row?.original?._id,
                          "Approved"
                        );
                      }}
                    >
                      <i className="ri-check-line align-bottom me-2 text-muted"></i>
                      {""}
                      Approve
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickDelete(
                          cellProps?.row?.original?._id,
                          "Rejected"
                        );
                      }}
                    >
                      <i className="ri-close-line align-bottom me-2 text-muted"></i>{" "}
                      Reject
                    </DropdownItem>
                  </>
                ) : (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(cellProps?.row?.original, "both");
                    }}
                  >
                    {cellProps?.row?.original?.status === "Rejected" ? (
                      <>
                        <i className="ri-check-fill align-bottom me-2 text-muted"></i>{" "}
                        Approve
                      </>
                    ) : (
                      <>
                        <i className="ri-close-fill align-bottom me-2 text-muted"></i>{" "}
                        Reject
                      </>
                    )}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
            <span>
              <Button
                className="btn mx-10"
                style={{ paddingY: "0px", height: "35px" }}
                color={"danger"}
                onClick={() => {
                  onBlogDelete(cellProps?.row?.original?._id);
                }}
              >
                Delete
              </Button>
            </span>
          </div>
        );
      },
    },
  ]);

  const handleDeleteUser = () => {
    setLoader(true);
    dispatch(
      ChangeBlogsStatus({
        appIds: userStatus.appIds,
        status: userStatus.status,
      })
    )
      .then((res) => {
        if (res?.payload?.success === true) {
          setLoader(false);
          if (userStatus?.status !== "Approved") {
            toast.success("Rejected successfully");
          } else {
            toast.success("Approved successfully");
          }
          setDeleteModal(false);
          dispatch(
            getBlogs({
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              status: customerStatus.value ? customerStatus.value : null,
              page: page,
              createdBy: "User",
            })
          );
        } else if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.message);
      });
  };
  return (
    <>
      <div className="page-content">
        <Modal
          isOpen={blogDetailModal}
          size="xl"
          scrollable={true}
          centered
          id="exampleModal"
        >
          <ModalHeader
            toggle={() => {
              setBlogDetailModal(false);
            }}
          >
            Blog Details
          </ModalHeader>
          <ModalBody>
            <BlogPreviewModal singleBlog={profileData} isWriteBlog={false} />
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setBlogDetailModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
        <DeleteModal
          show={deleteModal}
          text={userStatus?.status === "Approved" ? "Approved" : "Rejected"}
          onDeleteClick={() => handleDeleteUser()}
          onCloseClick={() => setDeleteModal(false)}
          loader={loader}
        />
        <DeleteModal
          show={blogDelete}
          text={"Delete"}
          onDeleteClick={() => handleDeleteBlog()}
          onCloseClick={() => setBlogDelete(false)}
          loader={loader}
        />
        <ImagePreviewModal
          show={imageModal}
          src={imageLink?.img}
          text={imageLink.name}
          onCloseClick={() => setImageModal(false)}
        />
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Blogs</h5>
                  {toolCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {toolLoader || isTrendingLoader || isEditorPickLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {toolData && toolData?.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={toolData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          isCustomerFilter={true}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-borderless"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Blogs..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={toolCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          isAdditionalStatus={true}
                          totalDataCount={toolCount}
                          editorpickTrendingBlogcustomerStatus={
                            editorpickTrendingBlogcustomerStatus
                          }
                          setEditorpickTrendingBlogcustomerstatus={
                            setEditorpickTrendingBlogcustomerstatus
                          }
                          editorpickTrendingBlogcustomerstatus={
                            editorpickTrendingBlogcustomerstatus
                          }
                          ispaginationshow={
                            toolCount > 10 && limit < toolCount ? true : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Blogs..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            isAdditionalStatus={true}
                            editorpickTrendingBlogcustomerStatus={
                              editorpickTrendingBlogcustomerStatus
                            }
                            setEditorpickTrendingBlogcustomerstatus={
                              setEditorpickTrendingBlogcustomerstatus
                            }
                            editorpickTrendingBlogcustomerstatus={
                              editorpickTrendingBlogcustomerstatus
                            }
                          />
                        </>
                      )}
                    </>
                  )}

                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default index;
